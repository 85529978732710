import React from "react";
import { Snackbar, Alert } from "@mui/material";

const SuccessSnackBar = ({ open, setOpen }) => {

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <Snackbar open={open} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={6000} onClose={handleClose}>
            <Alert
                onClose={handleClose}
                severity="success"
                variant="filled"
                sx={{ width: '100%' }}
            >
                Budget enviado com sucesso!
            </Alert>
        </Snackbar>
    )
}

export default SuccessSnackBar;