import React from 'react';
import { FormControl, Select, MenuItem, Typography } from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { menuClasses } from "@mui/material/Menu";
import { selectClasses } from "@mui/material/Select";
import colors from "../../utils/colors.json"
import Status from '../Status/Status';

const SelectStatus = ({ label, status, setStatus, options }) => {

    return (
        <FormControl
            sx={{
                flexGrow: 2,
                minWidth: "100%",
                '@media (max-width: 768px)': {
                    flexGrow: 'initial',
                    width: "60%"
                }
            }}>
            <Typography
                component="p"
                sx={{
                    fontSize: ".9rem",
                    color: colors.noir,
                    fontWeight: 600,
                    marginBottom: "0.5em"
                }}
            >
                {label}
            </Typography >
            <Select
                disableUnderline
                displayEmpty
                variant="standard"
                MenuProps={{
                    anchorOrigin: {
                        vertical: "center",
                        horizontal: "left",
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                    },
                    sx: {
                        boxShadow: 0,
                        marginBlock: "0.9rem",
                        [`& .${menuClasses.paper}`]: {
                            borderRadius: "0px 0px 10px 10px",
                            boxShadow: 0,
                            borderColor: colors.noir,
                            border: "1.2px solid",
                            borderTop: 0,
                        },
                        [`& .${menuClasses.list}`]: {
                            paddingTop: 0,
                            paddingBottom: 0,
                            background: "white",
                            "& li": {
                                padding: "12px",
                            },
                            "& li:hover": {
                                background: colors.light_gray_2,
                            },
                            "& li.Mui-selected": {
                                color: "white",
                                background: colors.light_gray_2,
                            },
                            "& li.Mui-selected:hover": {
                                background: colors.light_gray_2,
                            },
                        },
                    },
                }}
                IconComponent={ExpandMoreIcon}
                value={status}
                onChange={(event) => setStatus(event.target.value)}
                sx={{
                    borderColor: colors.noir,
                    [`& .${selectClasses.select}`]: {
                        background: "transparent",
                        color: colors.noir,
                        borderRadius: "12px",
                        borderColor: colors.noir,
                        border: "1.5px solid",
                        padding: "10px 15px",
                        "&:focus": {
                            borderRadius: "12px",
                            background: "transparent",
                            borderColor: colors.noir,
                        },
                    },
                    [`& .${selectClasses.icon}`]: {
                        right: "12px",
                    },
                }}
            >
                {options.map((option, i) => {
                    if (option.description !== 'Budget') {
                        return <MenuItem key={i} divider={true} value={option.id}>
                            <Status status={option.description} fullwidth={'true'} sx={{ width: '95%' }} />
                        </MenuItem>
                    }
                })}
            </Select>
        </FormControl>
    )
}

export default SelectStatus;