import colors from "../../utils/colors.json"
import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    border: 1.5px solid  ${colors.light_gray};
    border-radius: 10px;
    background: transparent;
    width: 100%;
    overflow: hidden;

    .terms-header{
        background-color: ${colors.gray};
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 10px;
        font-weight: 500;
        color: ${colors.white};
    }
`
export const AddButton = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding: 10px;
    cursor: pointer;
    width: fit-content;
    transition: all .2s ease;

    &:hover{
        color: ${colors.gray};
    }

    &:active{
        transform: scale(0.95);
    }
` 