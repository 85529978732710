import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: 0,
    paddingBottom: 15,
    transition: 'all 0.5s ease',
    background: 'transparent'
}));

export default AccordionDetails;
