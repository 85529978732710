import { styled } from 'styled-components';
import colors from "../../utils/colors.json"

export const ContainerRow = styled.div`
   display: flex;
   padding: 10px;
   align-items: center;
   gap: 10px;
   border-bottom: 1.5px solid ${colors.gray};

   .terms-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
   }

   .terms-container ol{
    padding-left: 15px;
   }

   .title-term{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    min-width: 250px;

    p{   
        font-weight: 500 !important;
    }
   }
`; 