import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar/Navbar";
import { Outlet } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { mainTheme } from "./themes/mainTheme"
import { CompanyProvider } from "./contexts/CompanyContext";

const App = () => {
    const [innerHeight, setInnerHeight] = useState(window.innerHeight);
    useEffect(() => {
        window.addEventListener('resize', () => {
            setInnerHeight(window.innerHeight)
        })
    }, [])

    return (
            <CompanyProvider>
                <ThemeProvider theme={mainTheme}>
                    <div style={{
                        padding: '20px 50px',
                        height: innerHeight + "px",
                        background: "linear-gradient(0deg, #FFFFFF 15.85%, #d3d3d39e 90.86%)"
                    }}>
                        <Navbar />
                        <Outlet />
                    </div>
                </ThemeProvider>
            </CompanyProvider>
    )
}

export default App;