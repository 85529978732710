import React, { cloneElement, createElement, useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import CustomButton from "../../CustomButton/CustomButton";
import CloseIcon from '@mui/icons-material/Close';
import TextFieldComp from '../../TextFieldComp/TextFieldComp'
import TermFieldComp from '../../TermFieldComp/TermFieldComp'
import { DialogModal, ModalTitle, ModalContent, ModalActions } from "./style"
import parse from 'html-react-parser';
import api from '../../../services/api'
import { Link } from "react-router-dom";

const EditTermModal = ({ open, handleClose, term, budgetVersionId, actualTermId }) => {
    const [termDescription, setTermDescription] = useState('')
    var parsedTerm = parse(term.text);
    const [termText, setTermText] = useState(undefined);
    const [termList, setTermList] = useState(undefined);

    useEffect(() => {
        if (parsedTerm) {
            setTermDescription(term.description)
            if (parsedTerm.length === undefined && parsedTerm.type === "ol") {
                setTermList(parsedTerm.props.children)
            }

            if (parsedTerm.length === undefined && parsedTerm.type === "p") {
                setTermText(parsedTerm.props.children)
            }

            if (parsedTerm.length > 0) {
                parsedTerm.forEach((t) => {
                    if (t.type === 'p') {
                        setTermText(t.props.children)
                    }
                    if (t.type === 'ol') {
                        if (t.props.children.length === undefined) {

                            setTermList([t.props.children])
                        } else {
                            setTermList(t.props.children)
                        }
                    }
                })
            }
        }
    }, [term])

    const handleAddNewItem = () => {
        var newItem = createElement('li', { id: termList.length }, '');
        setTermList(prevList => [...prevList, newItem])
    }

    const handleEditListItem = (e) => {
        var newTermList = [...termList];
        newTermList[e.target.id] = cloneElement(newTermList[e.target.id], {}, e.target.value)
        setTermList(newTermList);
    }

    const concatTermText = (text, list) => {
        var concat = '';
        if (text) {
            concat = `<p>${text}</p>`
        }

        if (list) {
            var listString = ''
            list.forEach((item) => {
                listString += `<li>${item.props.children}</li>`
            })
            concat += `<ol>${listString}</ol>`
        }
        return concat
    }

    const handleSaveEdit = () => {
        var updateBudgetTermURL = '/BudgetTerms/UpdateBudgetVersionTerms'

        var formData = {
            budgetId: budgetVersionId,
            actualTermId: actualTermId,
            termDescription: termDescription,
            termText: concatTermText(termText, termList)
        }

        api.post(updateBudgetTermURL, formData).then((response) => {
            return response.data;
        }).then((data) => {
            if (data === 'Termo atualizado com sucesso!') {
                window.location.reload();
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <DialogModal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ModalTitle className="title-large">
                Editar Cláusula
            </ModalTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: "#000"
                })}
            >
                <CloseIcon />
            </IconButton>
            <ModalContent>
                <TextFieldComp
                    label='Título'
                    fullWidth={true}
                    type='text'
                    value={termDescription}
                    setValue={setTermDescription} 
                />

                <TextFieldComp
                    label='Texto'
                    fullWidth={true}
                    multiline
                    rows={6}
                    setValue={setTermText}
                    value={termText}
                    sx={{ paddingTop: '5px' }}
                />

                {termList === undefined &&
                    <Link className={"title-small"} onClick={() => { setTermList([]) }}>Adicionar lista de itens</Link>
                }

                {termList &&
                    <div className={'ol-container'}>
                        <p className='title-medium'>
                            Lista de itens

                            <CustomButton
                                sx={{ width: '50%' }}
                                onClick={handleAddNewItem} variant={"outlined"}>
                                <span className="title-small">
                                    + Adicionar item
                                </span>
                            </CustomButton>
                        </p>

                        {termList.map((row, i) => {
                            return <TermFieldComp
                                key={i}
                                id={i.toString()}
                                label={i + 1}
                                fullWidth={true}
                                multiline
                                onChange={handleEditListItem}
                                defaultValue={row && row.props.children}
                                sx={{ paddingTop: '5px' }}
                            />
                        })}
                    </div>
                }
            </ModalContent>

            <ModalActions>
                <CustomButton sx={{ minWidth: '50%' }} onClick={handleSaveEdit} variant={"contained"}>
                    <span className="title-small">
                        Salvar
                    </span>
                </CustomButton>
            </ModalActions>
        </DialogModal>
    )
}

export default EditTermModal;