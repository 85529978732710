import React from "react";
import { Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, } from "@mui/material";
import CustomButton from "../../CustomButton/CustomButton";

const DeleteVersionModal = ({ open, handleClose }) => {

    const handleDelete = () => {
        // request delete
    }

    return (
        <Dialog
            PaperProps={{
                sx: { borderRadius: '10px', padding: '20px', gap: '20px', minWidth: '30%' }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <DialogTitle sx={{ padding: 0 }} className="title-large">
                Excluir versão
            </DialogTitle>
            <DialogContent sx={{ padding: 0 }}>
                <DialogContentText className="title-medium">
                    Tem certeza que deseja excluir a versão X?
                </DialogContentText>
            </DialogContent>

            <DialogActions sx={{ padding: 0 }}>
                <CustomButton onClick={handleClose} variant={"outlined"} fullWidth>
                    <span className="title-small">Cancelar</span>
                </CustomButton>
                <CustomButton onClick={handleDelete} variant={"contained"} fullWidth>
                    <span className="title-small">
                        Excluir
                    </span>
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteVersionModal;