import styled from "styled-components";
import { List } from '@mui/material';
import colors from "../../utils/colors.json"


export const CustomList = styled(List)({
    border: `solid 1.5px ${colors.light_gray}`,
    borderRadius: '10px',
    // overflow: 'hidden',
    padding: 0,
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
})