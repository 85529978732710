import React, { useState } from "react";
import { AssetRow } from "../JobBody/style";
import { IconButton } from '@mui/material';
import { VerticalAlignBottom } from '@mui/icons-material';
import DeleteAssetModal from '../Modal/DeleteAssetModal/DeleteAssetModal';
import { useParams } from "react-router-dom";
import api from "../../services/api";

const JobAssetRow = ({ jobId, blob }) => {
    const { companyName } = useParams();
    const [openDeleteAsset, setOpenDeleteAsset] = useState(false);

    // const handleDeleteAsset = () => {
    //     setOpenDeleteAsset(true);
    // }

    const handleDownloadBlob = async () => {
        var downloadBlobFile = '/BlobStorage/download-asset/'
        var formData = {
            "blobId": blob.id,
            "jobId": jobId,
            "companyName": window.localStorage.getItem("companyName")
        }
        try {
            const response = await api.post(downloadBlobFile, formData, {
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', blob.name);
            document.body.appendChild(link);

            link.click();

            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Download failed', error);
        }
    }

    return (
        <AssetRow>
            <span className='label-large'>{blob.name}</span>
            <div className='action-row'>
                <IconButton onClick={handleDownloadBlob}>
                    <VerticalAlignBottom fontSize='small' />
                </IconButton>
                {/* <IconButton onClick={handleDeleteAsset}>
                    <DeleteOutlined fontSize='small' />
                </IconButton> */}
            </div>
            <DeleteAssetModal open={openDeleteAsset} handleClose={() => { setOpenDeleteAsset(false) }} />
        </AssetRow>
    )
}
export default JobAssetRow;