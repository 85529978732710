import React from "react";
import { Dialog, IconButton, DialogTitle, DialogContent, DialogActions, } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import QuotationSummary from "../../QuotationSummary/QuotationSummary";
import BudgetTerms from "../../BudgetTerms/BudgetTerms";

var version = {
    "budgetVersionId": 3,
    "version": 1,
    "createdAt": "20/12/2024",
    "due": 300,
    "isActive": true,
    "budgetResume": [
        {
            "formatPersonalizationId": 5,
            "formatId": 1,
            "formatName": "Media Display/ Banner HTML5",
            "formatTypeId": 1,
            "formatType": "Master até 15 segundos",
            "formatTypeQuantity": 1
        }
    ],
    "terms": [
        {
            "id": 3,
            "text": "<p>A proposta contempla até 02 ajustes nas peças produzidas sendo a 3a considerada uma nova peça. *Consideramos ajustes pequenos, pedidos de alteração em peças já produzidas, ajustes de média e alta complexidade são consideradas novas peças criativas.</p>",
            "description": "Ajustes",
            "position": 3
        },
        {
            "id": 4,
            "text": "<p>Se houver necessidade de equipe em dias e horários fora do citado acima, de acordo com a urgência ou exigências, será implementado o sistema de plantão e uma <b>taxa de urgência de 50% do valor inicial é acrescida ao valor total do orçamento</b>.</p>",
            "description": "Equipe fora do horário comercial",
            "position": 4
        },
        {
            "id": 6,
            "text": "<p>A Replica não se responsabiliza pelo licenciamento, uso de imagem, uso intelectual ou patrimonial das imagens e assets fornecidos pelo cliente e/ou agência.</p>",
            "description": "Imagens das peças",
            "position": 6
        },
        {
            "id": 7,
            "text": "<p>Em caso de cancelamento da produção após seu início, a agência ou cliente serão responsáveis pelo pagamento de 50% do custo total do orçamento.</p>",
            "description": "Faturamento e prazo de pagamento",
            "position": 7
        },
        {
            "id": 8,
            "text": "<p>Em caso de cancelamento da produção após seu início, a agência ou cliente serão responsáveis pelo pagamento de 50% do custo total do orçamento.</p>",
            "description": "Cancelamento",
            "position": 8
        },
        {
            "id": 26,
            "text": "<ol><li><b>Display HTML5/ GIFS/ Estáticos</b><br>- Adaptação criativa masters: 4 a 6 horas - 3 formatos(vertical, horizontal e quadrado).<br>- Após aprovação de masters - Replicação deformatos: 4 a 40 horas (a depender da quantidade de deliveries)</li><li><b>Vídeos MP4/MOV - Social Ads ou DOOH</b><br>- Adaptação criativa masters: 6 a 8 horas - 3 formatos(vertical, horizontal e quadrado)<br>- Após aprovação de masters - Replicação de formatos: 8 a 40 horas (adepender da quantidade de deliveries)</li><li>*Horas consideradas em dias úteis e horário comercial - segunda a sexta-feira de 9h00 às 19h00.</li></li></ol>",
            "description": "SLA/ cronograma de entrega",
            "position": 2
        },
        {
            "id": 27,
            "text": "<p>O início da produção será considerado quando atender a um dos seguintes critérios:</p><ol><li><p>Aprovação do orçamento assinado dentro do sistema Replica.</p></li><li><p>Aprovação dos assets da campanha (key visual, PSD master e planilha de Requisição de Material) que deverão ser enviados pela plataforma Replica.</p></li></ol>",
            "description": "Início da Produção",
            "position": 1
        },
        {
            "id": 28,
            "text": "<p>Este orçamento não contempla produção ou licenciamento de trilhas sonoras, tão quanto gravação de locuções. Podemos aplicar áudio finalizado no tempo da peça, se enviado como asset e após ser avaliado pela equipe de Replica.</p>",
            "description": "Áudio",
            "position": 5
        },
        {
            "id": 29,
            "text": "<p>Veja as tags</p><p></p><ol><li><p>mais tags</p></li><li><p>lista</p></li></ol><p></p><p><strong>Agora negrito</strong></p><p></p><p><strong><em>agora italico e negrito </em></strong><br><br><em>so italico</em></p><p></p><ol><li><p><strong>Lista negrito</strong></p></li><li><p><strong><em>Lista italico negrito</em></strong></p></li><li><p><em>italico </em><br></p></li></ol><ul><li><p>bullet list</p></li></ul>",
            "description": "TEste de termo novo ",
            "position": 9
        }
    ]
}

const BudgetResumeModal = ({ open, handleClose }) => {

    return (
        <Dialog
            PaperProps={{
                sx: {
                    borderRadius: '10px',
                    padding: '20px', gap: '20px',
                    minWidth: "350px", width: '90%', maxWidth: "unset"
                }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <DialogTitle sx={{ padding: 0 }} className="title-small">
                Budget aprovado - Versão {version.version} | {version.createdAt}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: "#000"
                })}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ padding: 0 }}>
                <QuotationSummary readonly summary={version.budgetResume} due={version.due} budgetVersionId={version.budgetVersionId} />
                <br />
                <BudgetTerms readonly={true} terms={version.terms} budgetVersionId={version.budgetVersionId} />
            </DialogContent>
        </Dialog>
    )
}

export default BudgetResumeModal;