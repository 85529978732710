import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';

const StyledButton = styled(Button)(({ theme, variant, fullWidth}) => ({
  borderRadius: '50px',
  padding: '5px 20px',
  fontSize: '14px',
  boxShadow: 'none',
  textTransform: 'none',
  ...(fullWidth === "true" && {
    width: "100%"
  }),
  ...(variant === 'contained' && {
    backgroundColor: '#2B2926',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#1E1C1A',
    },
  }),
  ...(variant === 'outlined' && {
    backgroundColor: 'transparent',
    border: '2px solid #2B2926',
    color: '#2B2926',
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
  }),
}));

const CustomButton = ({ variant, children, ...props }) => {
  return (
    <StyledButton variant={variant} {...props}>
      {children}
    </StyledButton>
  );
};

export default CustomButton;