import React, { createContext, useEffect, useState } from 'react';
import api from '../services/api';
export const StatusContext = createContext();

export const StatusProvider = ({ children }) => {
    const [statusList, setStatusList] = useState([]);

    useEffect(() => {
        var allCompaniesURL = '/Status/GetStatuses/'

        api.get(allCompaniesURL).then((response) => {
            return response.data;
        }).then((data) => {
            setStatusList(data);
        }).catch((error) => {
            console.log(error)
        })
    }, [])

    const getStatusFromId = (id) => {
        var obj = statusList.filter((opt) => opt.id === id)
        return obj[0].description
    };

    const getIdFromStatus = (status) => {
        var obj = statusList.filter((opt) => opt.description === status)
        return obj[0].id
    };

    return (
        <StatusContext.Provider value={{ getStatusFromId, getIdFromStatus, statusList }}>
            {children}
        </StatusContext.Provider>
    );
};
