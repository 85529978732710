import { useEffect, useState } from 'react';
import { Button, Popover, Dialog, RangeCalendar, Heading, CalendarGrid, CalendarCell } from './style';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const DateRangePicker = ({ _ref, isOpen, handleClose, setFiltered, initialList }) => {
    let [range, setRange] = useState(0);

    const handleChangeDate = (e) => {
        setRange(e);
    }
    const handleClearRange = (e) => {
        setRange(0);
    }

    useEffect(() => {
        if (range !== 0) {
            filterBudgetByRangeDate();
        }

        if (range === 0) {
            setFiltered(initialList);
        }
        handleClose()
    }, [range])

    const filterBudgetByRangeDate = () => {
        var fromDate = range.start.toDate()
        var toDate = range.end.toDate()

        const filteredList = initialList.filter((item) => {
            var budgetDateSplitted = item.createdAt.split("/");

            let budgetDate = new Date(budgetDateSplitted[2], budgetDateSplitted[1] - 1, budgetDateSplitted[0])

            return budgetDate.getTime() >= fromDate.getTime() &&
                budgetDate.getTime() <= toDate.getTime();
        });

        setFiltered(filteredList);
    }

    return (
        <Popover isOpen={isOpen} triggerRef={_ref} trigger='calendarButton' placement='bottom right'>
            <Dialog>
                <RangeCalendar value={range} onChange={handleChangeDate} v>
                    <header>
                        <Button slot="previous"><ArrowBackIos fontSize='small' /></Button>
                        <Heading className='title-medium' />
                        <Button slot="next"><ArrowForwardIos fontSize='small' /></Button>
                    </header>
                    <CalendarGrid>
                        {(date) => <CalendarCell date={date} className="title-small-date" />}
                    </CalendarGrid>
                </RangeCalendar>
                <Link to={false} onClick={handleClearRange} className={"title-small"}>Limpar seleção</Link>
            </Dialog>
        </Popover>

    )
}

export default DateRangePicker;