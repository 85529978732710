import React, { useState, useEffect } from 'react';
import { ReactComponent as RIcon } from '../../icons/r-vector.svg';
import { Container, Links } from './style';
import { useLocation } from 'react-router-dom';
import MenuDrop from '../MenuDrop/MenuDrop';

export default function Navbar() {
  let location = useLocation();

  const [email, setEmail] = useState();

  useEffect(() => {
    if (window.localStorage.getItem('user__obj') !== "" &&
      window.localStorage.getItem('user__obj') !== null
    ) {
      setEmail(JSON.parse(window.localStorage.getItem('user__obj')).email);
    }
  }, [])

  var auth =
    location.pathname === '/' ||
    location.pathname === '/my-profile' ||
    location.pathname === '/forgot-password' ||
    location.pathname === '/reset-password' ||
      location.pathname === '/finish-register'
      ? false : true;

  return (
    <Container>
      <div className={"navbar-wrapper"}>
        <div className={"icons"}>
          <a href={auth ? "/" : "https://www.byreplica.io/"} className={"icons"}>
            <RIcon />
          </a>
          {auth && <div className='IconName title-small'>
            GP
          </div>}
        </div>

        {auth &&
          <Links>
            <a href="/budgets" className={`title-small ${location.pathname.includes('budgets') && 'active'}`}>BUDGETS</a>
            <a href="/empresas" className={`title-small ${location.pathname.includes('empresas') && 'active'}`}>EMPRESAS</a>
          </Links>}

        {auth &&
          <div className='user-mail'>
            <MenuDrop email={email} />
          </div>}
      </div>
    </Container>
  );
}
