import styled, { css } from "styled-components";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";

export const DialogModal = styled(Dialog)`
    .MuiDialog-paper{
        border-radius: 10px; 
        padding: 20px; 
        gap: 20px; 
        min-width: 300px;
        width: 30%;

        ${(props) => css`
            ${props.sx}  
        `}
    }
`

export const ModalTitle = styled(DialogTitle)`
    padding: 0 !important;
`

export const ModalContent = styled(DialogContent)`
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const ModalActions = styled(DialogActions)`
    justify-content: center!important;
    padding: 0!important;
`

