import React, { useState, useEffect } from 'react';
import Accordion from "./Accordion.jsx"
import { Skeleton } from "@mui/material"
import { PaperCustom } from './style.jsx';
import api from '../../services/api.js';

const BudgetCalculator = ({ summary, setUpdatedPayload }) => {
    const [expanded, setExpanded] = useState(false);
    const [formats, setFormats] = useState([]);
    const [budget, setBudget] = useState([]);

    const handleChange =
        (panel) => (event, newExpanded) => {
            setExpanded(newExpanded ? panel : false);
        };

    const addItemToBudget = (id, item) => {
        if (item.length > 0) {
            setBudget(prevBudget => {
                const newBudget = [...prevBudget];
                newBudget[id] = item; 
                return newBudget;
            });
        }
    };

    const createBudgetPayload = (list) => {
        var payload = [];
        list.forEach((format) => {
            if (format !== undefined && format.length > 0) {
                format.forEach((spec) => {
                    const { id, total, ...obj } = spec;
                    payload.push(obj)
                })
            }
        })
        setUpdatedPayload({
            items: [...payload]
        })
        console.log(payload)
    }

    const getFormatsAndPrices = () => {
        const getFormatsURL = "/Formats/formats"

        api.get(getFormatsURL)
            .then((response) => {
                return response.data;
            }).then((data) => {
                setFormats(data)
            }).catch((e) => {
                console.error(e)
            })
    }

    useEffect(() => {
        if (budget.length > 0) {
            createBudgetPayload(budget)
        }
    }, [budget])

    useEffect(() => {
        getFormatsAndPrices()
    }, [])

    return (
        <>
            {formats.length > 0 ?
                <PaperCustom
                    elevation={0}
                    variant="outlined"
                >
                    {formats && formats.map((format, i) => {
                        return <Accordion
                            key={i}
                            id={i}
                            expanded={expanded === `panel${i}`}
                            onChange={handleChange(`panel${i}`)}
                            format={format}
                            summary={summary}
                            addItemToBudget={addItemToBudget}
                        />
                    })} </PaperCustom>
                :
                <Skeleton
                    animation="wave"
                    variant="rounded"
                    width={window.innerWidth < 500 ? '100%' : '40%'}
                    height={window.innerWidth < 500 ? '50vh' : '90%'}
                />
            }
        </>
    );
}

export default BudgetCalculator;