import React, { useContext } from "react";
import { Dialog, Fab, DialogTitle, DialogContent, DialogActions, } from "@mui/material";
import CustomButton from "../../CustomButton/CustomButton";
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

const UserWithoutCompanyAlert = ({ open, handleClose, companyId, userEmail, setOpenAddToCompany }) => {

    return (
        <Dialog
            PaperProps={{
                sx: { borderRadius: '10px', padding: '20px', gap: '20px', minWidth: '300px', width: '30%' }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <DialogTitle sx={{ padding: 0, gap: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} className="title-large">
                Ação necessária!
            </DialogTitle>

            <DialogContent sx={{ padding: 0 }}>
                <p className="body-large">
                    Para gerenciar os orçamentos deste cliente: <span className="bold">{userEmail} </span>
                    você precisa adicionar ele à uma empresa. Essa ação é irreversível.
                </p>
            </DialogContent>

            <DialogActions sx={{ padding: 0 }}>
                <CustomButton onClick={handleClose} variant={"outlined"} fullWidth>
                    <span className="title-small">Cancelar</span>
                </CustomButton>
                <CustomButton onClick={() => { setOpenAddToCompany(true); handleClose() }} variant={"contained"} fullWidth>
                    <span className="title-small">
                        Adicionar
                    </span>
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default UserWithoutCompanyAlert;