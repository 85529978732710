import React, { useContext } from "react";
import { Dialog, IconButton, DialogTitle, DialogContent, DialogActions, } from "@mui/material";
import CustomButton from "../../CustomButton/CustomButton";
import CloseIcon from '@mui/icons-material/Close';
import SelectStatus from "../../SelectStatus/SelectStatus";
import { StatusContext } from "../../../contexts/StatusContext";
import api from "../../../services/api";

const ChangeJobStatus = ({ open, handleClose, jobId, ...props }) => {
    const { statusList } = useContext(StatusContext)

    const handleSaveStatus = () => {
        var allCompaniesURL = `/Job/${jobId}/UpdateJobStatus/`
        var formData = {
            "statusId": props.status
        }

        api.put(allCompaniesURL, formData)
            .then((response) => {
                return response.data;
            }).then((data) => {
                if(data === 'Atualização feita com sucesso!'){
                    window.location.reload();
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    return (
        <Dialog
            PaperProps={{
                sx: { borderRadius: '10px', padding: '20px', gap: '20px', minWidth: '350px', width: '30%' }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <DialogTitle sx={{ padding: 0 }} className="title-large">
                Alterar Status do Job
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: "#000"
                })}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ padding: 0 }}>
                <SelectStatus options={statusList} {...props} />
            </DialogContent>

            <DialogActions sx={{ padding: 0, alignItems: 'center', justifyContent: 'center' }}>
                <CustomButton onClick={handleSaveStatus} variant={"contained"} sx={{ width: '50%' }}>
                    <span className="title-small">
                        Salvar
                    </span>
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default ChangeJobStatus;