import React, { useState } from "react";
import { ContainerRow } from "./style";
import { EditOutlined } from "@mui/icons-material"
import { IconButton } from "@mui/material";
import EditTermModal from "../Modal/TermModal/EditTermModal";
import parse from 'html-react-parser';

const TermRow = ({ id, description, text, index, budgetVersionId, readonly=false }) => {
    const [openEditTerm, setOpenEditTerm] = useState(false);

    const handleOpenEditTerm = () => {
        setOpenEditTerm(true);
    }

    return (
        <ContainerRow>
            <div className="title-term">
                <p className="label-medium">
                    {index + 1}. {description}
                </p>
                {!readonly &&
                    <IconButton onClick={handleOpenEditTerm}>
                        <EditOutlined fontSize="small" sx={{ color: "#000" }} />
                    </IconButton>}
            </div>

            <div className="body-medium terms-container">
                {parse(text)}
            </div>

            {openEditTerm && <EditTermModal
                position={index + 1}
                budgetVersionId={budgetVersionId}
                open={openEditTerm}
                actualTermId={id}
                handleClose={(e) => { setOpenEditTerm(false) }}
                term={{ description: description, text: text }} />
            }
        </ContainerRow>
    )
}

export default TermRow;