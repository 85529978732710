import React, { useEffect, useState } from "react"
import { StyledTableRow, StyledTableCell } from "../DefaultTable/DefaultTable"
import { useNavigate } from 'react-router-dom';
import CustomButton from "../CustomButton/CustomButton";
import AddUserToCompany from "../Modal/AddUserToCompany/AddUserToCompany";
import UserWithoutCompanyAlert from "../Modal/UserWithoutCompanyAlert/UserWithoutCompanyAlert";

const BudgetListRow = ({ budget }) => {
    const navigate = useNavigate();
    const [openAddToCompany, setOpenAddToCompany] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);

    const handleOpenBudget = () => {
        if (budget.companyName) {
            navigate(budget.id.toString());
        } else {
            setOpenAlert(true)
        }
    }

    const handleAddToCompany = () => {
        setOpenAddToCompany(true);
    };

    return (
        <StyledTableRow>
            <StyledTableCell onClick={handleOpenBudget} className='body-large' variant='no-outline'>{`Budget Nº` + budget.jobDescription}</StyledTableCell>
            <StyledTableCell onClick={handleOpenBudget} className='body-large' variant='no-outline'>{budget.createdAt}</StyledTableCell>
            <StyledTableCell onClick={handleOpenBudget} className='body-large' variant='no-outline'>
                {budget.companyName === null ? <span> {budget.clientEmail} <br/> {budget.temporaryCompanyName}</span> : budget.companyName}
            </StyledTableCell>

            {budget.temporaryCompanyName !== null &&
                <StyledTableCell sx={{ width: '300px', textAligh: 'center' }} className='body-large' variant='no-outline'>
                    <CustomButton onClick={handleAddToCompany} variant={'outlined'}>Adicionar á uma empresa</CustomButton>
                </StyledTableCell>}

            <AddUserToCompany open={openAddToCompany} handleClose={() => { setOpenAddToCompany(false) }} userEmail={budget.clientEmail} />
            <UserWithoutCompanyAlert open={openAlert} handleClose={() => { setOpenAlert(false) }} userEmail={budget.clientEmail} setOpenAddToCompany={setOpenAddToCompany}/>
        </StyledTableRow>
    )
}

export default BudgetListRow;