export const isExpired = (token) => {
    if (token) {
        var isExpired = false;
        var dateNow = new Date();

        if (token.exp * 1000 < dateNow.getTime())
            isExpired = true;

        return isExpired
    } else {
        return false
    }
}
export const checkToken = () => {
    if (isExpired(window.localStorage.getItem("jwt__token")) ||
        window.localStorage.getItem("jwt__token") === null ||
        window.localStorage.getItem("jwt__token") === ""
    ) {
        window.location.href = "/"
    }
}