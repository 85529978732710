import styled from "styled-components";
import colors from "../../utils/colors.json"

import {
    Button as _Button,
    CalendarCell as _CalendarCell,
    CalendarGrid as _CalendarGrid,
    Dialog as _Dialog,
    Heading as _Heading,
    Popover as _Popover,
    RangeCalendar as _RangeCalendar
} from 'react-aria-components';

export const Popover = styled(_Popover)`
    filter: drop-shadow(0 0 0.1rem ${colors.gray});
    background-color:  white;
    border-radius: 5px;
    padding: 10px 0;
    &[data-trigger=DateRangePicker] {
        max-width: unset;
    }
`
export const Button = styled(_Button)`
    background: ${colors.white};
    color: var(--highlight-foreground);
    border: 2px solid var(--field-background);
    forced-color-adjust: none;
    border-radius: 4px;
    border: none;
    margin-left: auto;
    width: 2rem;
    height: 2rem;
    padding: 0;
    font-size: 0.857rem;
    box-sizing: content-box;
    flex-shrink: 0;
    position: sticky;
    right: 0;

    &[data-focus-visible] {
    outline: 2px solid var(--focus-ring-color);
    outline-offset: 2px;
    }
`

export const Dialog = styled(_Dialog)`
    .title-small{
        padding-left: 10px;
        padding-top: 5px;
        color: ${colors.toranja}
    }
`
export const Heading = styled(_Heading)` 
    flex: 1;
    margin: 0;
    text-align: center;
    font-size: 1.375rem;
    padding: 5px;
`
export const RangeCalendar = styled(_RangeCalendar)`
    width: fit-content;
    max-width: 100%;
    color: var(--text-color);

    & header {
        display: flex;
        align-items: center;
        margin: 0 4px .5rem 4px;
    }

    & table {
        border-collapse: collapse;

        & td {
        padding: 2px 0;
        }
    }
`
export const CalendarGrid = styled(_CalendarGrid)`
    .react-aria-CalendarHeaderCell{
        font-family: 'Neue Haas Grotesk Text', sans-serif !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        font-style: normal;
        padding: 5px 0;
    }
`
export const CalendarCell = styled(_CalendarCell)`
    width: 2.286rem;
    line-height: 2.286rem;
    text-align: center;
    border-radius: 999px;
    cursor: pointer;
    outline: none;
    forced-color-adjust: none;

    &[data-outside-month] {
      display: none;
    }

    &[data-pressed] {
      background: ${colors.light_gray};
    }

    &[data-focus-visible] {
      outline: 2px solid ${colors.light_gray};
      outline-offset: -2px;
    }

    &[data-selected] {
        background: ${colors.toranja};
        color: ${colors.white};
        border-radius: 0;

        &[data-focus-visible] {
            outline-color: var(--highlight-foreground);
            outline-offset: -3px;
        }
    }

    &[data-selection-start] {
      border-start-start-radius: 999px;
      border-end-start-radius: 999px;
    }

    &[data-selection-end] {
      border-start-end-radius: 999px;
      border-end-end-radius: 999px;
    }
  
`