import React from 'react';
import { FormControl, TextField, Typography } from '@mui/material';
import colors from "../../utils/colors.json";

export default function TextFieldComp({ label, value, setValue, onChange, fullWidth, sx, ...props }) {

    return (
        <FormControl sx={{
            flexGrow: 1,
            width: fullWidth ? "100%" : "40%",
            '@media (max-width: 768px)': {
                flexGrow: 'initial',
                width: fullWidth ? "100%" : "40%"
            }
        }}>
            <Typography
                component="p"
                className='title-medium'
                sx={{
                    fontSize: ".9rem",
                    color: colors.noir,
                    fontWeight: 600,
                    marginBottom: "0.5em",
                    textAlign: 'start'
                }}
            >
                {label}
            </Typography >
            <TextField
                {...props}
                value={value}
                onChange={(event) => onChange ? onChange(event) : setValue(event.target.value)}
                variant="standard"
                onWheel={(e) => { e.target.blur() }}
                slotProps={{
                    input: {
                        disableUnderline: true,
                        sx: {
                            background: "transparent",
                            color: colors.noir,
                            borderRadius: "12px",
                            borderColor: colors.noir,
                            border: "1.5px solid",
                            boxSizing: "border-box",
                            padding: "2px 15px",
                            minHeight: "2rem",
                            "&:focus": {
                                borderRadius: "12px",
                                background: "transparent",
                                borderColor: colors.noir,
                            }, "input[type=number]": {
                                mozAppearance: "textfield"
                            },
                            "input[type=number]::-webkit-outer-spin-button": {
                                WebkitAppearance: "none",
                                margin: 0
                            }, "&.MuiInputBase-multiline": {
                                padding: "10px 15px !important"
                            },
                            "input[type=number]::-webkit-inner-spin-button": {
                                WebkitAppearance: "none",
                                margin: 0
                            },
                        }
                    }
                }
                }
                sx={{
                    minWidth: 50,
                    borderColor: colors.noir,
                }}
            />
        </FormControl >
    );
}
