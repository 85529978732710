import React from "react";
import Footer from "../../components/Footer/Footer";
import PasswordForm from "../../components/PasswordForm/PasswordForm";
import { UserPageContainer } from '../LoginPage/style'

const ResetPassword = () => {
   
    return (
        <UserPageContainer>
            <PasswordForm />
            <Footer />
        </UserPageContainer>
    )
}

export default ResetPassword; 