import { useState, useContext } from 'react';
import {
    Popover, ListItem, ListItemButton, Checkbox
} from '@mui/material';
import colors from "../../utils/colors.json"
import { StatusContext } from '../../contexts/StatusContext';

export default function FilterStatusPopover({ openFilter, anchorEl, onClose, setSelectedStatus, selectedStatus }) {
    const { statusList } = useContext(StatusContext)

    const handleToggle = (value) => () => {
        const currentIndex = selectedStatus.indexOf(value);
        const newChecked = [...selectedStatus];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setSelectedStatus(newChecked);
    };

    return (
        <Popover
            open={openFilter}
            anchorEl={anchorEl.current}
            onClose={onClose}
            slotProps={{
                backdrop: {
                    sx: { display: "none", pointerEvents: "none" }
                },
                paper: {
                    sx: {
                        backgroundColor: colors.carbon,
                        color: colors.white,
                        marginRight: "-10px"
                    }
                }
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            {statusList?.map((status) => {
                if (status.id > 1 && status.id < 6) {
                    return (
                        <ListItem
                            key={status.id}
                            disablePadding
                        >
                            <ListItemButton role={undefined} onClick={handleToggle(status.description)} dense>
                                <Checkbox
                                    edge="start"
                                    checked={selectedStatus.includes(status.description)}
                                    tabIndex={-1}
                                    disableRipple
                                    sx={{
                                        color: colors[status.description],
                                        '&.Mui-checked': {
                                            color: colors[status.description],
                                        }
                                    }}
                                />
                                <p className='title-small' style={{ color: colors[status.description] }}>{status.description}</p>
                            </ListItemButton>
                        </ListItem>
                    );
                }
            })}
        </Popover>
    );
}