import { createTheme } from "@mui/material";
import colors from "../utils/colors.json"

export const mainTheme = createTheme({
    palette: {
        // primary: {

        // },
        // secondary: {

        // },
        // error: {

        // },
        // info: {

        // },
        warning: {
            main: colors.warning,
            light: colors.warning,
            dark: colors.warning,
        }, 
        success: {
            main: colors.success,
            light: colors.success,
            dark: colors.success,
        }
    }
});