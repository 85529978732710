import React from "react";
import Footer from "../../components/Footer/Footer";
import ProfileForm from "../../components/ProfileForm/ProfileForm";
import { UserPageContainer } from '../LoginPage/style'

const MyProfilePage = () => {
   
    return (
        <UserPageContainer>
            <ProfileForm />
            <Footer />
        </UserPageContainer>
    )
}

export default MyProfilePage; 