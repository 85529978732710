import React, { useEffect, useState } from 'react';
import { InfoBox, JobHeaderContainer, StatusContainer } from './style'
import { IconButton } from '@mui/material';
import EditOutlined from "@mui/icons-material/EditOutlined";
import { Link } from "react-router-dom"
import Status from "../Status/Status"
import ChangeJobStatus from '../Modal/ChangeJobStatus/ChangeJobStatus';
import BudgetResumeModal from '../Modal/BudgetResumeModal/BudgetResumeModal';

const JobHeader = ({ job }) => {
    const [openEditStatus, setOpenEditStatus] = useState(false);
    const [openBudgetResume, setOpenBudgetResume] = useState(false);
    const [jobStatus, setJobStatus] = useState(undefined);

    const handleChangeStatus = () => {
        setOpenEditStatus(true);
    }

    useEffect(() => {
        if (job) {
            setJobStatus(job.statusId)
        }
    }, [job])

    return (
        job ?
            <JobHeaderContainer variant={"outlined"}>
                <InfoBox>
                    <p className='label-small'>
                        Replica portal ID: {job.id}
                    </p>
                    <p className='headline-small'>
                        {job.name} {" "}
                        {/* <IconButton>
                            <EditOutlined sx={{ color: '#000' }} />
                        </IconButton> */}
                    </p>
                    {/* <Link onClick={(e) => {
                        setOpenBudgetResume(true)
                    }} style={{ color: "#000" }} className='title-small'>
                        Budget aprovado
                    </Link> */}
                </InfoBox>

                <StatusContainer>
                    <Status status={job.status} />
                    <IconButton onClick={handleChangeStatus}>
                        <EditOutlined sx={{ color: '#000' }} />
                    </IconButton>
                </StatusContainer>

                <ChangeJobStatus jobId={job.id} status={jobStatus} setStatus={setJobStatus} open={openEditStatus} handleClose={() => { setOpenEditStatus(false) }} />

                {/* {openBudgetResume && <BudgetResumeModal open={openBudgetResume} handleClose={(e) => { setOpenBudgetResume(false) }} />} */}
            </JobHeaderContainer> : null
    );
};

export default JobHeader;
