import styled from "styled-components"
import { Paper } from '@mui/material';

export const PaperCustom = styled(Paper)`
    width:100%;
    border-radius:10px!important;
    border-color:white;
    border: 1.5px solid white !important;
    max-height:100%;
    overflow-y:auto;
    transition:all 0.5s ease;

    @media screen and(min-width: $wide-breakpoint) {
        .PaperCustom {
            border-width: 2px;
            margin-top: 5vh;
        }
    }

    @media screen and(max-width: $mobile-breakpoint) {
        .PaperCustom {
            margin-top: 0;
            width: 100%;
        }
    }
`