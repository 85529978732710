import React, { useEffect, useRef } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    TextField
} from '@mui/material'

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const InjectedTextField = ({ onChange, sx, isActiveSearch, ...props }) => {
    const inputRef = useRef()

    useEffect(() => {
        if (isActiveSearch) {
            console.log('focus')
            inputRef.current.focus()
        } else {
            inputRef.current.blur()
        }
    }, [isActiveSearch])

    return <ThemeProvider theme={darkTheme}>
        <TextField
            ref={inputRef}
            {...props}
            sx={{
                '& .MuiInput-underline:before': {
                    borderBottom: 'none',
                },
                '& .MuiInput-underline:after': {
                    borderBottom: 'none',
                },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                    borderBottom: 'none',
                },
                '& .MuiInput-underline:focus:before': {
                    borderBottom: 'none',
                }, ...sx
            }}
            className={"title-medium"}
            id="standard-basic"
            variant="standard"
            onChange={onChange}
            placeholder='Pesquise...'
        />
    </ThemeProvider>
}

export default InjectedTextField;