import React from "react";
import Footer from "../../components/Footer/Footer";
import LoginForm from "../../components/LoginForm/LoginForm";
import { UserPageContainer } from './style'

const LoginPage = () => {

    return (
        <UserPageContainer>
            <LoginForm />
            <Footer />
        </UserPageContainer>
    )
}

export default LoginPage; 