import React from 'react';
import '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { Divider, ListItemButton } from '@mui/material';
import { CustomList } from './style';

const CompaniesTable = ({companies}) => {
    const navigate = useNavigate();

    const handleOpenBudget = (company) => {
        navigate(company.name.toLowerCase(), { state: { companyId: company.id } });
        window.localStorage.setItem('companyName', company.name)
    }

    return (
        <CustomList>
            {companies.map((company, i) => (
                <div key={company.id} >
                    <ListItemButton
                        className='body-large'
                        onClick={() => { handleOpenBudget(company) }}
                        sx={{ padding: '15px 20px' }}>
                        {company.name}
                    </ListItemButton>
                    {i < companies.length - 1 && <Divider />}
                </div>
            ))}
        </CustomList>
    );
};

export default CompaniesTable;
