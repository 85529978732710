import React, { useState, useEffect } from 'react';
import MuiAccordion from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';
import AccordionSummary from './AccordionSumary.jsx';
import AccordionDetails from './AccordionDetails.jsx';
import FormatItem from './FormatItem.jsx';

const CustomAccordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    background: 'transparent',
    transition: 'all 0.5s ease',
    '&:last-child': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
    '& .Mui-expanded.MuiAccordionSummary-root': {
        background: "linear-gradient(0deg,#E3E8EA17.64%,#CBD8DC88.8%)"
    },
    '& .MuiCollapse-wrapper': {
        background: "linear-gradient(0deg,#E3E8EA17.64%,#CBD8DC88.8%)"
    },
}));

const Accordion = ({ format, id, addItemToBudget, ...props }) => {
    const [formatBudget, setFormatBudget] = useState([]);
    const [totalPriceFormat, setTotalPriceFormat] = useState(0);

    useEffect(() => {
        addItemToBudget(id, formatBudget)
    }, [formatBudget])

    return (
        <CustomAccordion {...props}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <p className='title-large-format'> {format.name} </p>
                <p className='title-price'>R$ {totalPriceFormat}</p>
            </AccordionSummary>
            <AccordionDetails>
                <FormatItem specsList={format.specs} setFormatBudget={setFormatBudget} {...props} />
            </AccordionDetails>
        </CustomAccordion>
    )
}

export default Accordion;
