import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import MyProfilePage from "./page/MyProfilePage/MyProfilePage"
import ResetPassword from "./page/ResetPassword/ResetPassword"
import LoginPage from "./page/LoginPage/LoginPage"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import BudgetsListPage from './page/BudgetsListPage/BudgetsListPage';
import CompanyListPage from './page/CompanyListPage/CompanyListPage';
import JobsListPage from './page/JobsListPage/JobsListPage';
import BudgetPage from './page/BudgetPage/BudgetPage';
import JobPage from './page/JobPage/JobPage';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<App />}>
        <Route path="/" element={<LoginPage />} />
        <Route path="/meu-perfil" element={<MyProfilePage />} />
        <Route path="/redefinir-senha" element={<ResetPassword />} />
        <Route path="/budgets" element={<BudgetsListPage />} />
        <Route path="/budgets/:id" element={<BudgetPage />} />
        <Route path="/empresas" element={<CompanyListPage />} />
        <Route path="/empresas/:companyName" element={<JobsListPage />} />
        <Route path="/empresas/:companyName/:jobId" element={<JobPage />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
