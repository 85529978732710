import { Backdrop as BDrop, CircularProgress } from "@mui/material"
import React from "react"

const Backdrop = ({ open }) => {

    return (
        <BDrop
            sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
            open={open}
        >
            <CircularProgress color="inherit" />
        </BDrop>
    )
}

export default Backdrop;
