import styled from "styled-components";
import { LoginPaper } from "../LoginForm/style";

export const ProfilePaper = styled(LoginPaper)`
`
export const AuthForm = styled.form`
    width: 100%;   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 25px;
`