import { styled } from 'styled-components';
import colors from "../../utils/colors.json"
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

export const VersionRow = styled(Accordion)`
    background-color:  ${colors.light_gray_2} !important;
    border-radius: 10px !important;
    border: none !important;
    margin: 0 !important;

    &:before {
        display: none;
    }
`;

export const Summary = styled(AccordionSummary)`
    .MuiAccordionSummary-content{
        display: flex;
        justify-content: space-between !important;
        align-items: center;
        width: 100% !important;
        margin: 0 !important;
    }
`;
export const Details = styled(AccordionDetails)`
   display: flex;
   flex-direction: column;
   gap: 20px;
   padding-top: 0 !important;
`;

export const VersionInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
`;

export const ActionButtons = styled.div`
    display: flex;
    gap: 5px;

    svg path{
        fill: ${colors.carbon};
    }
`;