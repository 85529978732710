import React, { useContext, useEffect, useState } from "react";
import { Dialog, IconButton, DialogTitle, DialogContent, DialogActions, } from "@mui/material";
import CustomButton from "../../CustomButton/CustomButton";
import CloseIcon from '@mui/icons-material/Close';
import SelectCompDefault from "../../SelectCompDefault/SelectCompDefault";
import TextFieldComp from "../../TextFieldComp/TextFieldComp";
import AddUserToCompanyAlert from "../AddUserToCompanyAlert/AddUserToCompanyAlert";
import { CompanyContext } from "../../../contexts/CompanyContext";
import api from "../../../services/api";
import Backdrop from "../../Backdrop/Backdrop"

const AddUserToCompany = ({ open, handleClose, resend, userEmail, ...props }) => {
    const [selectCompany, setSelectCompany] = useState("");
    const [newCompany, setNewCompany] = useState(false);
    const [newCompanyName, setNewCompanyName] = useState('');
    const [openAlert, setOpenAlert] = useState(false);
    const { companyList, getCompanies } = useContext(CompanyContext)
    const [loading, setLoading] = useState(false);

    const handleAddNewCompany = () => {
        setNewCompany(true);
    }

    const handleCreateCompany = () => {
        setLoading(true)
        var allCompaniesURL = `/Company/Create`
        var formData = {
            name: newCompanyName
        }

        api.post(allCompaniesURL, formData)
            .then((response) => {
                return response.data;
            }).then((data) => {
                if (data.message === "Company created successfully!") {
                    setLoading(false)
                    setNewCompany(false);
                    getCompanies();
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    const handleBackToCompanies = () => {
        setNewCompany(false);
    }

    const handleOpenAlert = () => {
        setOpenAlert(true);
    }

    useEffect(() => {
        if (!newCompany) {
            setNewCompanyName('')
        }
    }, [newCompany])

    return (
        <Dialog
            PaperProps={{
                sx: { borderRadius: '10px', padding: '20px', gap: '20px', minWidth: '350px', width: '30%' }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <DialogTitle sx={{ padding: 0 }} className="title-large">
                Adicionar usuário á uma empresa
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: "#000"
                })}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent sx={{ padding: 0, display: 'flex', flexDirection: 'column', gap: 3 }}>
                <p className="title-medium" style={{ margin: 0 }}>
                    Usuário: <span className="thin">{userEmail}</span>
                </p>

                {newCompany ?
                    <TextFieldComp fullWidth={'true'} placeholder={'Nome'} label={'Nova empresa:'} setValue={setNewCompanyName} value={newCompanyName} />
                    :
                    <SelectCompDefault labelKey={'name'} valueKey={'id'} label={'Empresas cadastradas:'} options={companyList} typeId={selectCompany} setTypeId={setSelectCompany} />
                }

            </DialogContent>


            <DialogActions sx={{ padding: 0, alignItems: 'center', justifyContent: 'center' }}>

                {newCompany ?
                    <CustomButton onClick={handleBackToCompanies} variant={"outlined"} sx={{ width: '50%' }}>
                        <span className="title-small">
                            Voltar
                        </span>
                    </CustomButton>
                    :
                    <CustomButton onClick={handleAddNewCompany} variant={"outlined"} sx={{ width: '50%' }}>
                        <span className="title-small">
                            + Nova empresa
                        </span>
                    </CustomButton>
                }

                {newCompany ?
                    <CustomButton onClick={handleCreateCompany} variant={"contained"} sx={{ width: '50%' }}>
                        <span className="title-small">
                            Salvar empresa
                        </span>
                    </CustomButton>
                    : <CustomButton onClick={handleOpenAlert} variant={"contained"} sx={{ width: '50%' }}>
                        <span className="title-small">
                            Solicitar
                        </span>
                    </CustomButton>}
            </DialogActions>
            {loading && <Backdrop open={loading} />}
            <AddUserToCompanyAlert open={openAlert} handleClose={() => { setOpenAlert(false) }} companyId={selectCompany} userEmail={userEmail} />
        </Dialog>
    )
}

export default AddUserToCompany;